<template>
  <div class="vld-parent">
    <loading :active="isLoading"
    :can-cancel="true"
    :loader="bars"
    :opacity='0.5'
    :on-cancel="onCancel"
    :is-full-page="fullPage"><img src="/image/loading.svg"></loading>
  </div>
  <main class="layout-main">
    <div class="inner">
      <div class="container-wrapper">
        <div class="layout-title">
          <h1 class="heading-page"><img src="@/assets/image/title/news.svg" alt="Works"><span class="sub">お知らせ</span></h1>
        </div>
      </div>

      <section class="container-bg">
        <div class="container-wrapper container-pad">

          <transition-group name="work" tag="ul" class="list-news"
            @before-enter="beforeEnter"
            @after-enter="afterEnter"
            @enter-cancelled="afterEnter">
            <li v-for="(post,index) in posts.data" :key="index" :data-index="index">
              <router-link :to="{ name: 'NewsDetail', params: { id: post.id }}">
                <div class="line">
                  <time class="date">{{ moment(post.date ) }}</time>
                  <p class="title">{{post.title.rendered}}</p>
                </div>
              </router-link>
            </li>
          </transition-group>

        </div>
      </section>
      </div>
  </main>
</template>

<script>
import { onMounted, onUpdated, onUnmounted,reactive, ref } from 'vue'
import axios from "axios"
import moment from 'moment'
import Loading from 'vue-loading-overlay';
require( 'vue-loading-overlay/dist/vue-loading.css')

export default {
  components: {
    Loading
  },
  setup() {
    const API_HOST = process.env.VUE_APP_APIRESTHOST;
    const isLoading = ref(false);
    const posts = reactive({
      data:null,
    })
    const getPosts = async () => {
      isLoading.value = true
      await axios.get('//' + API_HOST + '/c/wp-json/wp/v2/posts?order=asc&categories=1')
      .then( response => {
        posts.data = response.data
        setTimeout(() => {
          isLoading.value = false
        }, 300)
      }).catch(
        error => console.log(error)
      )
    }

    onMounted(() => {
      getPosts()
      //isList.value = true
    })
    onUpdated(() => {
      //console.log('updated!')
    })
    onUnmounted(() => {
      //console.log('unmounted!')
    })
    return{
      posts,
      isLoading
      //isList
    }
  },
  methods: {
    moment: function (date) {
      //console.log(date)
      return moment(date).format('YYYY.MM.DD')
    },
    beforeEnter(el) {
      //console.log( el  );
      el.style.transitionDelay = 100 * parseInt(el.dataset.index, 10) + 'ms'
    },
    afterEnter(el) {
      el.style.transitionDelay = ''
    }
  }
}

</script>

<style lang="scss">
  .work-item {

  }
  .work-enter-active,
  .work-leave-active {
    transition: all 0.6s ease-out;
  }
  .work-enter-from,
  .work-leave-to {
    opacity: 0;
    transform: translateY(50px);
  }

</style>

